import React, {useReducer, createContext} from 'react';
import {ACTION_TYPES} from "../util/consts";

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initState = {
    ageCheck: false,
    isAge: false,
};

function reducer(state, action) {
    switch(action.type) {
        case ACTION_TYPES.UPDATE_AGE_CHECK: {
            const {payload} = action;

            return {
                ...state,
                ageCheck: true,
                isAge: payload
            }
        }
        default:
            return state;
    }
}

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initState);
    console.log(state, initState);
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
};

export default GlobalContextProvider;